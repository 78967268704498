import { useTheme } from "./ThemeProvider";
import { MoonStar, Sun } from "lucide-react";
import { Button } from "~/shadcn/components/ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "~/shadcn/components/ui/tooltip";

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useTheme();
  const isLightTheme = theme === "light";
  const themeIcon = isLightTheme ? (
    <MoonStar className="h-6 w-6" aria-hidden />
  ) : (
    <Sun className="h-6 w-6" aria-hidden />
  );
  const themeText = isLightTheme ? "Switch to dark theme" : "Switch to light theme";

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button type="button" variant="outline" size="icon" onClick={() => toggleTheme()}>
          {themeIcon}
          <span className="sr-only">{themeText}</span>
        </Button>
      </TooltipTrigger>
      <TooltipContent side="bottom">{themeText}</TooltipContent>
    </Tooltip>
  );
};

export default ThemeSwitcher;
